import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { ImageModule } from 'primeng/image';
@Component({
  selector: 'core-icon',
  standalone: true,
  imports: [CommonModule, ImageModule],
  template: ``,
  styleUrl: './icon.component.css',
})
export class IconComponent {
  @HostBinding('style.-webkit-mask-image')
  private _path!: string;

  @Input()
  public set path(filePath: string) {
    this._path = `url("${filePath}")`;
  }
}
